import { ProductResult } from '@relewise/client';
import { Abbreviations, Units } from '../../../constants/units-and-abbreviations';
import { formatPrice, getImageCropUrl, isNullOrEmpty } from '../../../utils/helpers';
import {
    getCarPrivateLeasingDisclaimer,
    getVanFinancialLeasingDisclaimer,
    getVanOperationalLeasingDisclaimer,
} from '../../../utils/helpers/price-by-ownership.helper';
import { Bundle } from '../../api/models/hessel-api';
import { CarColor, CarDetails, LeasingTerms, PriceType, ProductCardPrice, VehicleAvailabilityType } from '../../api/models/hessel-api/hire';
import { BundleUSP } from '../../api/models/umbraco';
import { hesselViewModels } from '../../view-models';
import { CarSpecification, PdpResource } from '../../view-models/vehicle';
import { getFuelType, getRange } from './mapper.helper';
import sanitizeHtml from 'sanitize-html';

const colorsToPreview = 4;
const imagesToPreview = 4;
const currencyPrMonth = Abbreviations.KR_SLASH_MD;

export const mapBundleListToProductCards = (
    bundles: Bundle[],
    bundleUSPs: BundleUSP[],
    carsInBundles: CarDetails[]
): hesselViewModels.ProductCard[] => {
    const allCards: hesselViewModels.ProductCard[] = [];
    bundles.sort((a, b) => (a.id > b.id ? 1 : -1));
    bundles.sort((a, b) => (a.discountPrice > b.discountPrice ? 1 : -1));
    bundles.forEach((b) => {
        const car1 = carsInBundles.find((c) => c.vehicleId === b.variant1Id);
        const car2 = carsInBundles.find((c) => c.vehicleId === b.variant2Id);
        if (car1 && car2) {
            const bundleCard = mapBundleDetailsToProductCard(b, bundleUSPs, car1, car2);
            allCards.push(bundleCard);
        }
    });
    return allCards;
};

export function mapBundleDetailsToProductCard(
    bundle: Bundle,
    bundleUSPs: BundleUSP[],
    car1: CarDetails,
    car2: CarDetails
): hesselViewModels.ProductCard {
    const saving = bundle.normalPrice - bundle.discountPrice;
    const leasingPeriod = bundle.durationVehicle1 + bundle.durationVehicle2;
    const totalPriceText = `Totalpris for hele leasingperioden: ${formatPrice(bundle.discountPrice * leasingPeriod + bundle.establishmentFee)} kr.`;
    const totalKilometersText = `Tilgængeligt kilometerantal i hele leasingperioden: ${formatPrice(bundle.kmPrMo * leasingPeriod)} ${Units.km}`;
    const totalLeasingPeriodText = `Samlet leasingperiode er ${leasingPeriod} måneder.`;
    const brandString = car1.brand === car2.brand ? `2 x ${car1.brand}` : '';
    const extraSpecs = getExtraSpecsForBundle(bundle, car1, car2).filter((_, idx) => idx < 3);
    return {
        id: bundle.id,
        url: 'bundle.url',
        availability: 'InStock',
        type: 'detailed',
        imageResources: bundle.resources.map((x) => {
            return {
                type: 'image',
                url: getImageCropUrl(x.data, 'productlist'),
            };
        }),
        primaryImage: {
            type: 'image',
            url: getImageCropUrl(bundle.resources[0].data, 'productlist'),
        },
        brandModel: brandString,
        carVariant: `${bundle.variant1Value} + ${bundle.variant2Value}`,
        brand: bundle.name,
        name: `${bundle.variant1Value} + ${bundle.variant2Value}`,
        carType: '',
        uniqueCarId: bundle.id,
        additionalColors: 0,
        price: [
            {
                type: 'Bundle',
                label: '',
                value: `${formatPrice(bundle.discountPrice)} ${currencyPrMonth}`,
                disclaimer: {
                    label: `Før ${formatPrice(bundle.normalPrice)} ${Abbreviations.KR_SLASH_MD}`,
                    description: `${totalPriceText} ${totalKilometersText} ${totalLeasingPeriodText}`,
                },
            },
        ],
        keySpecs: [...bundleUSPs.map((x) => ({ key: x.property, value: x.value }))],
        extraSpecs: extraSpecs.length === 3 ? [...extraSpecs, '+ mere'] : extraSpecs,
        badge: `Spar ${formatPrice(saving)} ${currencyPrMonth}`,
        reserved: false,
        generalCampaignLabels: [],
        extraEquipmentLabel: 'Inkluderet udstyr bl.a.',
        itemNumber: bundle.id,
        category: 'bundle',
        modelYear: '',
        entityId: '',
    };
}

export function mapCarDetailsToProductCard(car: CarDetails): hesselViewModels.ProductCard {
    const allImages = getCarImages(car);
    const colors = car.colors;
    const colorsWithResources = colors.filter((x) => x.resource?.data);
    const brandString = car.modelTitle !== null ? `${car.brand}, ${car.modelTitle}` : car.brand;
    const viewModel: hesselViewModels.ProductCard = {
        id: car.vehicleId,
        url: car.url,
        availability: car.availability,
        type: 'detailed',
        imageResources: allImages,
        primaryImage: (allImages[0] as hesselViewModels.Image) ?? null,
        primaryColor: getPrimaryColor(car, allImages),
        brandModel: brandString,
        carVariant: car.name,
        brand: car.brand,
        name: car.name,
        carType: car.bodyType,
        uniqueCarId: car.vehicleId,
        referenceNumber: car.referenceNumber,
        additionalColors: colors.length - colorsToPreview,
        price: getVehiclePrices(car),
        otherColors: colorsWithResources.filter((_, idx) => idx < colorsToPreview).map((x) => getImageCropUrl(x.resource.data, 'iconsmall')),
        keySpecs: getKeySpecs(car),
        extraSpecs: getExtraSpecs(car),
        carSpecification: car.specification,
        reserved: false,
        generalCampaignLabels: car.campaigns,
        location: car.location ?? null,
        locationId: car.locationId?.toString() ?? null,
        itemNumber: car.itemNumber,
        height: car.specification.height,
        heightType: car.specification.heightType,
        length: car.specification.length,
        lenghtType: car.specification.lengthType,
        extraEquipmentLabel: car.availability === 'Used' ? 'Bilens udstyr' : 'Inkl. ekstraudstyr bl.a.',
        category: car.category,
        modelYear: car.modelYear ?? '',
        entityId: car.entityId.toString(),
    };
    return viewModel;
}

const getPrimaryColor = (car: CarDetails, allImages: hesselViewModels.PdpResource[]): CarColor | null => {
    if (car.availability === 'Demo') {
        return null;
    }
    if (car.color) {
        const primaryColor = car.colors.find((x) => x.code === car.color);
        if (primaryColor) return primaryColor;
    }
    const colorOfFirstImage = car.colors.find(
        (color) =>
            color.code === (allImages[0] as hesselViewModels.Image)?.colorName || color.code === (allImages[0] as hesselViewModels.Image)?.colorCode
    );
    if (colorOfFirstImage) return colorOfFirstImage;
    if (car.colors.length > 0) return car.colors[0];
    return null;
};

const getCarImages = (car: CarDetails): PdpResource[] => {
    const imagesInCorrectColor = car.resources.filter(
        (x) => (car.availability === 'Used' || car.availability === 'Engros' || car.color === null || car.color === x.colorCode) && x.type === 'Image'
    );
    const filtered = imagesInCorrectColor.filter((_res, index) => index < imagesToPreview);
    return filtered.map((x, idx) => {
        return {
            type: 'image',
            url: getImageCropUrl(x.data, 'productdetails'),
            showMoreOverlay: idx + 1 === filtered.length,
            colorName: x.colorName,
            colorCode: x.colorCode,
        };
    });
};

const getKeySpecs = (car: CarDetails): { key: string; value: string }[] => {
    const carSpecification = car.specification;
    const horsePower = { key: 'HK', value: carSpecification.horsePower ?? '-' };
    const zeroToHundred = {
        key: '0-100 KM/T',
        value: carSpecification.zeroToOneHundred ? `${formatPrice(carSpecification.zeroToOneHundred, 1)} s` : '-',
    };
    const fuelType = getFuelType(carSpecification.fuelType.length > 0 ? carSpecification.fuelType : '-');
    const mixedDriving = getRange(
        carSpecification.fuelType === 'El' ? carSpecification.range : carSpecification.mixedDriving,
        carSpecification.fuelType
    );
    const towingWeight = {
        key: 'TRÆKVÆGT',
        value: formatPrice(car.specification.maximumTowingWeight),
    };
    const bootCapacity = {
        key: 'LASTVOLUMEN',
        value: car.specification.bootCapacity ?? '-',
    };
    const milage = {
        key: 'KM STAND',
        value: formatPrice(car.specification.odometerReadingInKm ?? '') ?? '-',
    };
    const modelYear = {
        key: 'ÅRGANG',
        value: car.modelYear ?? '-',
    };
    const payload = {
        key: 'NYTTELAST',
        value: formatPrice(carSpecification.payload),
    };
    if (car.category === 'Car') {
        if (car.availability === 'Used') {
            return [milage, modelYear, mixedDriving, fuelType];
        }
        return [mixedDriving, horsePower, fuelType, zeroToHundred];
    } else if (car.category === 'Van') {
        if (car.availability === 'Order' || car.availability === 'InStock') return [mixedDriving, horsePower, towingWeight, payload];
        if (car.availability === 'Used' || car.availability === 'Engros') return [modelYear, milage, horsePower, towingWeight];
        return [horsePower, towingWeight, mixedDriving, bootCapacity];
    }
    return [];
};

const getKeySpecsFromRelewiseModel = (car: ProductResult): { key: string; value: string }[] => {
    const carSpecification = car.data?.Specification.value.data;
    if (!carSpecification) return [];
    const horsePower = { key: 'HK', value: carSpecification.HorsePower?.value ?? '-' };
    const zeroToHundred = {
        key: '0-100 KM/T',
        value: carSpecification.ZeroToOneHundred ? `${formatPrice(Number(carSpecification.ZeroToOneHundred.value), 1)} s` : '-',
    };
    const fuelType = getFuelType(carSpecification.FuelType?.value ?? '-');
    const mixedDriving = getRange(
        carSpecification.FuelType?.value === 'El' ? carSpecification.Range?.value : carSpecification.MixedDriving?.value,
        carSpecification.FuelType?.value
    );
    const towingWeight = {
        key: 'TRÆKVÆGT',
        value: formatPrice(carSpecification.MaximumTowingWeight?.value),
    };
    const bootCapacity = {
        key: 'LASTVOLUMEN',
        value: carSpecification.BootCapacity?.value ?? '-',
    };
    const milage = {
        key: 'KM STAND',
        value: formatPrice(carSpecification.OdometerReadingInKm?.value ?? '') ?? '-',
    };
    const modelYear = {
        key: 'ÅRGANG',
        value: car.data?.ModelYear?.value ?? '-',
    };
    const payload = {
        key: 'NYTTELAST',
        value: carSpecification.Payload?.value ?? '-',
    };
    if (car.data?.CategoryTypeCode?.value === 'c') {
        if (car.data?.AvailabilityCode?.value === 'u') {
            return [milage, modelYear, mixedDriving, fuelType];
        }
        return [mixedDriving, horsePower, fuelType, zeroToHundred];
    } else if (car.data?.CategoryTypeCode?.value === 'v') {
        if (car.data?.AvailabilityCode?.value === 'o' || car.data?.AvailabilityCode?.value === 's')
            return [mixedDriving, horsePower, towingWeight, payload];
        if (car.data?.AvailabilityCode?.value === 'u' || car.data?.AvailabilityCode?.value === 'e')
            return [modelYear, milage, horsePower, towingWeight];
        return [horsePower, towingWeight, mixedDriving, bootCapacity];
    }
    return [];
};

const getExtraSpecsForBundle = (bundle: Bundle, car1: CarDetails, car2: CarDetails) => {
    const extraSpecsLabels: string[] = [];
    bundle.equipmentPackages1.forEach((ep) => {
        const match = car1.equipmentPackages.find((x) => x.packageIdKey === ep);
        if (match && match.name) extraSpecsLabels.push(match.name);
    });
    bundle.equipmentPackages2.forEach((ep) => {
        const match = car2.equipmentPackages.find((x) => x.packageIdKey === ep);
        if (match && match.name) extraSpecsLabels.push(match.name);
    });
    bundle.equipments1?.forEach((eq) => {
        const match = car1.equipment.find((x) => x.equipmentIdCode === eq);
        if (match) extraSpecsLabels.push(match.name);
    });
    bundle.equipments2.forEach((eq) => {
        const match = car2.equipment.find((x) => x.equipmentIdCode === eq);
        if (match) extraSpecsLabels.push(match.name);
    });
    return [...new Set(extraSpecsLabels)];
};

const getExtraSpecs = (car: CarDetails): string[] => {
    if (car.category === 'Van') {
        return [
            car.specification.fuelType,
            car.specification.transmissionType,
            `Totalvægt ${(car.specification.totalWeight / 1000).toFixed(1).replace('.', ',')} t.`,
            '+ mere',
        ];
    }
    if (car.availability === 'Order') {
        return [
            car.specification.transmissionType,
            `Co2 ${formatPrice(car.specification.co2Emission)} g/km`,
            `${car.specification.newtonMeter?.split('+')[0]} NM`,
            '+ mere',
        ];
    } else if (car.availability === 'Used') {
        if (car.equipmentNames === null) return [];
        const more = car.equipmentNames.length > 3 ? ['+ mere'] : [];
        return [...car.equipmentNames.filter((_, idx) => idx < 3), ...more];
    } else {
        const extraEquipmentPackage = car.equipmentPackages.filter(
            (x) => x.hireEquipmentStatus === 'Extra' || x.hireEquipmentStatus === 'ExtraIncluded'
        );
        const extraEquipment = car.equipment.filter((x) => x.hireEquipmentStatus === 'Extra' || x.hireEquipmentStatus === 'ExtraIncluded');
        const combinedList = [...extraEquipmentPackage, ...extraEquipment];
        const more = combinedList.length > 3 ? ['+ mere'] : [];
        return [...combinedList.filter((_, idx) => idx < 3).map((x) => x.name ?? ''), ...more];
    }
};

const getExtraSpecsFromRelewiseModel = (car: ProductResult): string[] => {
    if (!car.data) return [];
    const carData = car.data;
    const carSpecification = carData.Specification?.value.data;
    if (carData.CategoryTypeCode?.value === 'v') {
        return [
            carSpecification.FuelType?.value,
            carSpecification.TransmissionType?.value,
            `Totalvægt ${(carSpecification.TotalWeight?.value / 1000).toFixed(1).replace('.', ',')} t.`,
            '+ mere',
        ];
    }
    if (carData.AvailabilityCode?.value === 'o') {
        return [carSpecification.TransmissionType?.value, `${carSpecification.NewtonMeter?.value} NM`, '+ mere'];
    } else {
        if (!carData.EquipmentIncludedInCash || !carData.EquipmentIncludedInCash.value || !carData.EquipmentIncludedInCash.value.$values) return [];
        const more = carData.EquipmentIncludedInCash.value.$values.length > 3 ? ['+ mere'] : [];
        return [...carData.EquipmentIncludedInCash.value.$values.filter((_: string, idx: number) => idx < 3), ...more];
    }
};

const getVehiclePrices = (vehicle: CarDetails) => {
    const allRelevantPrices: ProductCardPrice[] = [];
    if (vehicle.purchaseTypes.cash.show) {
        allRelevantPrices.push({
            type: 'Cash',
            label: 'Kontant',
            value: `${formatPrice(vehicle.purchaseTypes.cash.basePrice)} kr.`,
            rawPriceValue: vehicle.purchaseTypes.cash.basePrice,
        });
    }
    const isOrderFordVan = vehicle.category === 'Van' && vehicle.brand === 'Ford' && vehicle.availability === 'Order';
    if (vehicle.purchaseTypes.financing.show && !(vehicle.category === 'Van' && vehicle.availability === 'Used') && !isOrderFordVan) {
        allRelevantPrices.push({
            type: 'Financing',
            label: 'Finansiering',
            value: `${formatPrice(vehicle.purchaseTypes.financing.baseMonthlyPrice)} ${currencyPrMonth}`,
            rawPriceValue: vehicle.purchaseTypes.financing.baseMonthlyPrice,
            disclaimer: {
                label: `Udbetaling ${formatPrice(vehicle.purchaseTypes.financing.downPayment)} kr.`,
                description: vehicle.purchaseTypes.financing.santanderComplianceText
                    ? sanitizeHtml(vehicle.purchaseTypes.financing.santanderComplianceText, { allowedTags: [] })
                    : '-',
            },
        });
    }
    if (vehicle.purchaseTypes.taxation.show) {
        allRelevantPrices.push({
            type: 'Taxation',
            label: 'Beskatningspris',
            value: `fra ${formatPrice(vehicle.purchaseTypes.taxation.basePrice)} kr.`,
            rawPriceValue: vehicle.purchaseTypes.taxation.basePrice,
        });
    }
    if (vehicle.category === 'Car') {
        if (vehicle.purchaseTypes.hire.show) {
            allRelevantPrices.push({
                type: 'HiRE',
                label: 'Hessel HiRE',
                value: `${vehicle.availability === 'Order' ? 'fra ' : ''}${
                    vehicle.purchaseTypes.hire.baseMonthlyPrice ? formatPrice(vehicle.purchaseTypes.hire.baseMonthlyPrice) : '-'
                } ${currencyPrMonth}`,
                rawPriceValue: vehicle.purchaseTypes.hire.baseMonthlyPrice,
                disclaimer: {
                    label: 'Udbetaling 0 kr.',
                    description: `Totalpris i leasingperioden (inkl. etableringsgebyr) fra ${formatPrice(
                        vehicle.purchaseTypes.hire.baseMonthlyPrice * vehicle.purchaseTypes.hire.durationFrom +
                            (vehicle.purchaseTypes.hire.establishmentFee ?? 0)
                    )} kr. Løbetid fra ${vehicle.purchaseTypes.hire.durationFrom}-${
                        vehicle.purchaseTypes.hire.durationTo
                    } mdr. med tilgængelige kilometer fra ${formatPrice(
                        vehicle.purchaseTypes.hire.kilometersPerMonth * vehicle.purchaseTypes.hire.durationFrom
                    )} ${Units.km}. Aftaleindgåelse kræver positiv kreditvurdering.`,
                },
            });
        }
        if (vehicle.purchaseTypes.privateLeasing.show && vehicle.purchaseTypes.privateLeasing.leasingTerms) {
            allRelevantPrices.push({
                type: 'Private leasing',
                label: 'Privatleasing',
                value: `${formatPrice(vehicle.purchaseTypes.privateLeasing.baseMonthlyPrice)} ${currencyPrMonth}`,
                rawPriceValue: vehicle.purchaseTypes.privateLeasing.baseMonthlyPrice,
                disclaimer: {
                    label: `Udbetaling ${formatPrice(vehicle.purchaseTypes.privateLeasing.downPayment)} kr.`,
                    description: getCarPrivateLeasingDisclaimer(
                        getConsumption(vehicle.specification),
                        `${vehicle.specification.co2Emission} ${Abbreviations.G_SLASH_KM}`,
                        vehicle.specification.fuelType !== 'El' && !isNullOrEmpty(vehicle.specification.energyLabel)
                            ? 'energimærke: ' + vehicle.specification.energyLabel
                            : '',
                        vehicle.purchaseTypes.privateLeasing.baseMonthlyPrice,
                        vehicle.purchaseTypes.privateLeasing.downPayment,
                        vehicle.purchaseTypes.privateLeasing.establishmentFee ?? 0,
                        vehicle.purchaseTypes.privateLeasing.totalCreditCostIncludingSetUpCost,
                        vehicle.purchaseTypes.privateLeasing.durationMonths,
                        vehicle.purchaseTypes.privateLeasing.kilometersPerYear,
                        false,
                        vehicle.purchaseTypes.privateLeasing.leasingTerms
                    ),
                },
            });
        }
    }

    if (vehicle.purchaseTypes.operationalLeasing.show) {
        allRelevantPrices.push({
            type: 'Operational leasing',
            label: 'Operationel leasing',
            value: `${formatPrice(vehicle.purchaseTypes.operationalLeasing.baseMonthlyPrice)} ${currencyPrMonth}`,
            rawPriceValue: vehicle.purchaseTypes.operationalLeasing.baseMonthlyPrice,
            disclaimer: {
                label: `Udbetaling ${formatPrice(vehicle.purchaseTypes.operationalLeasing.downPayment)} kr.`,
                description: getVanOperationalLeasingDisclaimer(
                    getConsumption(vehicle.specification),
                    `${vehicle.specification.co2Emission} ${Abbreviations.G_SLASH_KM}`,
                    vehicle.specification.energyLabel ?? '',
                    vehicle.purchaseTypes.operationalLeasing.baseMonthlyPrice,
                    vehicle.purchaseTypes.operationalLeasing.downPayment,
                    vehicle.purchaseTypes.operationalLeasing.durationMonths,
                    vehicle.purchaseTypes.operationalLeasing.kilometersPerYear,
                    vehicle.purchaseTypes.operationalLeasing.establishmentFee ?? 0,
                    vehicle.purchaseTypes.operationalLeasing.pantOwnerDeclaration,
                    +vehicle.specification.greenOwnerFee,
                    false,
                    vehicle.purchaseTypes.operationalLeasing.leasingTerms as LeasingTerms,
                    vehicle.purchaseTypes.operationalLeasing.pricePerOvertakingKilometers
                ),
            },
        });
        if (!vehicle.purchaseTypes.financialLeasing.show && vehicle.category === 'Car') {
            allRelevantPrices.push({
                type: 'Financial leasing',
                label: 'Finansiel leasing',
                value: 'Kontakt for pris',
                rawPriceValue: 0,
                disclaimer: {
                    label: 'Læs mere',
                    description:
                        'Vi har ikke på nuværende tidspunkt en pris på denne leasingtype men kontakt os gerne, og så hjælper vi dig med en pris og afklaring af eventuelle spørgsmål.',
                },
            });
        }
    }
    if (vehicle.purchaseTypes.financialLeasing.show) {
        if (!vehicle.purchaseTypes.operationalLeasing.show && vehicle.category === 'Car') {
            allRelevantPrices.push({
                type: 'Operational leasing',
                label: 'Operationel leasing',
                value: 'Kontakt for pris',
                rawPriceValue: 0,
                disclaimer: {
                    label: 'Læs mere',
                    description:
                        'Vi har ikke på nuværende tidspunkt en pris på denne leasingtype men kontakt os gerne, og så hjælper vi dig med en pris og afklaring af eventuelle spørgsmål.',
                },
            });
        }
        allRelevantPrices.push({
            type: 'Financial leasing',
            label: 'Finansiel leasing',
            value: `${formatPrice(vehicle.purchaseTypes.financialLeasing.baseMonthlyPrice)} ${currencyPrMonth}`,
            rawPriceValue: vehicle.purchaseTypes.financialLeasing.baseMonthlyPrice,
            disclaimer: {
                label: `Udbetaling ${formatPrice(vehicle.purchaseTypes.financialLeasing.downPayment)} kr.`,
                description: getVanFinancialLeasingDisclaimer(
                    getConsumption(vehicle.specification),
                    `${vehicle.specification.co2Emission} ${Abbreviations.G_SLASH_KM}`,
                    vehicle.specification.energyLabel ?? '',
                    vehicle.purchaseTypes.financialLeasing.baseMonthlyPrice,
                    vehicle.purchaseTypes.financialLeasing.downPayment,
                    vehicle.purchaseTypes.financialLeasing.durationMonths,
                    vehicle.purchaseTypes.financialLeasing.kilometersPerYear,
                    +vehicle.specification.greenOwnerFee,
                    vehicle.purchaseTypes.financialLeasing.residualValue,
                    false,
                    vehicle.purchaseTypes.financialLeasing.leasingTerms as LeasingTerms,
                    vehicle.purchaseTypes.financialLeasing.pricePerOvertakingKilometers
                ),
            },
        });
    }
    if (vehicle.purchaseTypes.engros.show) {
        allRelevantPrices.push({
            type: 'Engros',
            label: 'Kontant afhentningspris',
            value: `${formatPrice(vehicle.purchaseTypes.engros.basePrice)} ${Abbreviations.KR}`,
            rawPriceValue: vehicle.purchaseTypes.engros.basePrice,
            disclaimer: {
                description: '',
                label: 'Ex. moms',
            },
        });
    }

    return allRelevantPrices;
};

function getConsumption(specification: CarSpecification) {
    return specification.fuelType === 'El'
        ? formatPrice(specification.range ?? 0, 0) + ` ${Units.kWh}/100 ${Units.km}`
        : formatPrice(specification.mixedDriving, 1) + ` ${Abbreviations.KM_SLASH_L}`;
}

export const mapRelewiseToProductCard = (vehicle: ProductResult): hesselViewModels.ProductCard => {
    const getAvailability = (availability: string) => {
        switch (availability) {
            case 'u':
                return 'Used' as VehicleAvailabilityType;
            case 'o':
                return 'Order' as VehicleAvailabilityType;
            case 'd':
                return 'Demo' as VehicleAvailabilityType;
            case 'e':
                return 'Engros' as VehicleAvailabilityType;
            case 's':
                return 'InStock' as VehicleAvailabilityType;
            default:
                return 'Used' as VehicleAvailabilityType;
        }
    };

    const purchaseTypes = vehicle.data?.PurchaseTypes?.value.data;

    return {
        id: vehicle.productId ?? '',
        url: vehicle.data?.Url.value ?? '',
        availability: getAvailability(vehicle.data?.AvailabilityCode.value ?? 'u'),
        type: 'detailed',
        imageResources: [
            {
                type: 'image',
                url: vehicle.data?.ImageUrl.value ?? '',
            },
        ],
        primaryImage: {
            type: 'image',
            url: vehicle.data?.ImageUrl.value ?? '',
        },
        brandModel: vehicle.brand?.displayName
            ? `${vehicle.brand?.displayName}${vehicle.data?.ModelTitle.value ? `, ${vehicle.data?.ModelTitle.value}` : ''}`
            : '',
        carVariant: vehicle.data?.Name.value ?? '',
        brand: vehicle.brand?.displayName ?? '',
        name: vehicle.data?.Name.value ?? '',
        carType: vehicle.data?.BodyType.value ?? '',
        uniqueCarId: vehicle.data?.ReferenceNumber.value ?? '',
        additionalColors: 0,
        price:
            getAvailability(vehicle.data?.AvailabilityCode.value ?? 'u') === 'Engros' &&
            purchaseTypes &&
            purchaseTypes.Engros &&
            purchaseTypes.Engros.value.data.BasePrice.value
                ? [
                      {
                          type: 'Engros',
                          label: 'Kontant afhentningspris',
                          value: `${formatPrice(purchaseTypes.Engros.value.data.BasePrice.value.amount)} ${Abbreviations.KR}`,
                      },
                  ]
                : [
                      {
                          type: 'Cash',
                          label: 'Kontant',
                          value: `${formatPrice(vehicle.salesPrice ?? 0)} kr.`,
                          rawPriceValue: vehicle.salesPrice ?? 0,
                      },
                      ...(purchaseTypes &&
                      purchaseTypes.Financing &&
                      purchaseTypes.Financing.value.data.BaseMonthlyPrice?.value &&
                      purchaseTypes.Financing.value.data.DownPayment?.value &&
                      purchaseTypes.Financing.value.data.SantanderComplianceText?.value &&
                      !(vehicle.data?.CategoryTypeCode?.value === 'v' && vehicle.data?.AvailabilityCode?.value === 'u')
                          ? [
                                {
                                    type: 'Financing' as PriceType,
                                    label: 'Finansiering',
                                    value: `${formatPrice(purchaseTypes.Financing.value.data.BaseMonthlyPrice.value.amount)} ${currencyPrMonth}`,
                                    rawPriceValue: purchaseTypes.Financing.value.data.BaseMonthlyPrice.value.amount,
                                    disclaimer: {
                                        label: `Udbetaling ${formatPrice(purchaseTypes.Financing.value.data.DownPayment.value.amount)} kr.`,
                                        description: purchaseTypes.Financing.value.data.SantanderComplianceText.value
                                            ? sanitizeHtml(purchaseTypes.Financing.value.data.SantanderComplianceText.value, { allowedTags: [] })
                                            : '-',
                                    },
                                },
                            ]
                          : []),
                  ],
        keySpecs: getKeySpecsFromRelewiseModel(vehicle),
        extraSpecs: getExtraSpecsFromRelewiseModel(vehicle),
        reserved: false,
        generalCampaignLabels: [],
        itemNumber: vehicle.productId ?? '',
        category: vehicle.data?.AvailabilityCode.value ?? '',
        modelYear: vehicle.data?.ModelYear.value ?? '',
        entityId: vehicle.productId ?? '',
        extraEquipmentLabel:
            vehicle.data?.CategoryTypeCode?.value === 'v'
                ? 'Øvrige specifikationer'
                : vehicle.data?.AvailabilityCode?.value === 'u'
                ? 'Bilens udstyr'
                : 'Inkl. ekstraudstyr bl.a.',
    };
};
